const _ = require("lodash");

export function createNode() {
  const node = {
    id: generateUUID(),
    label: "",
    stepLabel: "",
    type: "",
    wasRenamed: false,
    selectedContentId: "",
    isDataSelected: false,
    formatType: "circle",
    hasNextNode: true,
    color: "#9747ff",
    icon: "mdi-calendar",
    isNew: true,
    lastNode: null,
    nextNode: null,
    lastJoinNode: null,
    nextJoinNode: null,
    nodeListIndex: null,
    nodeListIndexSelf: null,
    nodeListIndexLength: null,
  };

  console.log(node);

  return node;
}

export async function findNodeById(node, id) {
  if (node.id === id) {
    return node;
  } else {
    if (node.nextNode) {
      const foundNode = await findNodeById(node.nextNode, id);
      if (foundNode) {
        return foundNode;
      }
    }

    if (node.YesNext) {
      const foundNode = await findNodeById(node.YesNext, id);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return null;
}

export function linkNodes(nodeList) {
  // Criar um mapa para facilitar o acesso aos nós pelo ID
  const nodeMap = new Map();
  for (const node of nodeList) {
    nodeMap.set(node.id, node);
  }

  // Link dos nós usando os campos lastNode e nextNode
  for (const node of nodeList) {
    if (node.lastNode !== null) {
      const lastNode = nodeMap.get(node.lastNode);
      if (lastNode) {
        if (!node.fromClick || node.fromClick === "next")
          lastNode.nextNode = node;
        else {
          if (lastNode.fromClick !== "yes") lastNode.YesNext = node;
          else lastNode.nextNode = node;
        }
      }
    }
  }

  // Retornar o primeiro nó da lista (cabeça)
  return nodeList.find((node) => node.lastNode === null);
}

export function buscarIndiceProfundidade(objeto, id, indice = 0) {
  // Verifica se o objeto atual tem o ID desejado
  if (objeto.id === id) {
    return indice; // Retorna o índice de profundidade
  }

  // Verifica se o objeto tem uma próxima propriedade "nextNode"
  if (objeto.nextNode) {
    // Chama recursivamente a função para o próximo nó
    const indiceProximoNodo = buscarIndiceProfundidade(
      objeto.nextNode,
      id,
      indice + 1
    );
    if (indiceProximoNodo !== -1) {
      return indiceProximoNodo; // Retorna o índice encontrado
    }
  }

  // Verifica se o objeto tem uma propriedade "YesNext"
  if (objeto.YesNext) {
    // Chama recursivamente a função para o nó "YesNext"
    const indiceYesNext = buscarIndiceProfundidade(
      objeto.YesNext,
      id,
      indice + 1
    );
    if (indiceYesNext !== -1) {
      return indiceYesNext; // Retorna o índice encontrado
    }
  }

  return -1; // Retorna -1 caso o ID não seja encontrado
}

export function buildCronStringV2(schedule) {
  let cronObj = {
    hora: null,
    minuto: null,
    diaSemana: null,
    diaMes: null,
    tipoRecorrencia: null,
    valorRecorrencia: null,
  };
  // Execução imediata
  if (schedule.init === "immediate") {
    cronObj = null;
    return cronObj;
  }

  if (schedule.timerType === "punctual") {
    cronObj = null;
    cronObj = `${schedule.init}T${schedule.executeTime}`;
    return cronObj;
  }

  if (schedule.executeTime) {
    const [hour, minute] = schedule.executeTime.split(":");
    cronObj.hora = parseInt(hour);
    cronObj.minuto = parseInt(minute);
  }

  cronObj.tipoRecorrencia = schedule.interval;
  cronObj.valorRecorrencia = schedule.every;

  console.log(schedule);

  if (schedule.interval === "weekly")
    cronObj.diaSemana = schedule.intervalValues.map((x) => x).join(",");
  if (schedule.interval === "monthly") cronObj.diaMes = schedule.intervalValues;

  return JSON.stringify(cronObj);
}

export function buildCronString(schedule) {
  let cronString = "";
  // Execução imediata
  if (schedule.init === "immediate") {
    cronString = null;
    return cronString;
  }

  if (schedule.timerType === "punctual") {
    cronString = null;
    cronString = `${schedule.init}T${schedule.executeTime}`;
    return cronString;
  }

  if (schedule.executeTime) {
    const [hour, minute] = schedule.executeTime.split(":");

    cronString += `0 ${parseInt(minute)} ${parseInt(hour)} `;
  } else {
    cronString += `0 0 0 `;
  }

  if (schedule.startType === "date" && schedule.timerType === "punctual") {
    const dateInit = schedule.init.split("-");
    cronString += `${dateInit[2]} ${dateInit[1]} *`;
    return cronString;
  }
  // eslint-disable-next-line no-debugger
  // debugger;
  if (schedule.interval === "daily") {
    if (schedule.startType === "date" && schedule.timerType === "recurring") {
      const dateInit = schedule.init.split("-");
      const dia = dateInit[2];
      cronString += `${parseInt(dia)}/${parseInt(schedule.every)}`;

      cronString += ` ${parseInt(dateInit[1])}/1 *`;
    } else {
      if (schedule.startType === "immediate") {
        cronString += `* * *`;
      } else {
        const dateInit = schedule.init.split("-");
        const dia = dateInit[2];
        cronString += `${parseInt(dia)}/${parseInt(schedule.every)}`;
        const month = new Date().getMonth() + 1;
        if (month.toString() === dateInit[1]) {
          cronString += ` * *`;
        } else {
          cronString += ` ${parseInt(dateInit[1])}/1 *`;
        }
      }
    }

    return cronString;
  } else if (schedule.interval === "weekly") {
    const daysOfWeek = schedule.intervalValues.join(",");
    cronString += `?`;
    if (schedule.startType === "immediate") {
      cronString += ` *`;
    } else {
      const dateInit = schedule.init.split("-");
      const mes = dateInit[1];
      cronString += ` ${parseInt(mes)}/1`;
    }
    cronString += ` ${daysOfWeek}`;
    return cronString;
  } else if (schedule.interval === "monthly") {
    const dia = schedule.intervalValues.split(" ")[1];
    cronString += `${dia} `;
    if (schedule.startType === "immediate") {
      cronString += `${new Date().getMonth() + 1}/${schedule.every}`;
    } else {
      const dateInit = schedule.init.split("-");
      const mes = dateInit[1];
      cronString += ` ${parseInt(mes)}/${schedule.every}`;
    }
    cronString += " ?";
    cronString = cronString.slice(2, cronString.length);
    return cronString;
  }
}

export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export async function joinInformationsService({ node, tree, parent_diamond }) {
  // caso já não tenha sido do type union, fazer a conversão do mesmo
  if (!node.type.includes("union")) node.type = `union-${node.type}`;

  if (parent_diamond) {
    // chamar método para alterar árvore, fazendo apontar para o nó em questão
    const obj = {
      tree,
      parent_diamond,
      clicked_node: node,
      imutable_tree: tree,
    };
    const new_tree = await alterTreeToJoinService(obj);

    return new_tree;
  }
}

export async function alterTreeToJoinService({
  tree,
  parent_diamond,
  imutable_tree,
  clicked_node,
}) {
  const ultimo_yes = await findLastFinalToRemoveService(
    parent_diamond,
    "YesNext"
  );

  const ultimo_next = await findLastFinalToRemoveService(
    parent_diamond,
    "nextNode"
  );

  if (clicked_node.fromClick === "yes") {
    // fazer a busca no next afim de buscar o ultimo elemento
    // se for do tipo final, ele tem que ser removido
    if (ultimo_next) {
      const NEW_DATAS = await testRemoveAndJoinNodeService(
        tree,
        ultimo_yes.id,
        clicked_node.id,
        imutable_tree
      );

      return NEW_DATAS;
    }
  } else {
    if (ultimo_yes) {
      const NEW_DATAS = await testRemoveAndJoinNodeService(
        tree,
        ultimo_yes.id,
        clicked_node.id,
        imutable_tree
      );

      return NEW_DATAS;
    }
  }
}

// eslint-disable-next-line no-unused-vars
export async function testRemoveAndJoinNodeService(
  node,
  toDeleteId,
  toUpdateId,
  tree = null
) {
  // preciso receber: a arvore, o nó que vai ser removido e o nó onde vai receber uma nova informação
  const IS_TO_ENTER_NEXTNODE =
    node.nextNode &&
    node.nextNode.id === toDeleteId &&
    node.nextNode.formatType !== "diamond";

  const IS_TO_ENTER_YESNEXT =
    node.YesNext &&
    node.YesNext.id === toDeleteId &&
    node.YesNext.formatType !== "diamond";

  // COLOCAR O FILHO DO NÓ ENCONTRADO, APONTANDO PARA SER O NOVO NÓ
  if (IS_TO_ENTER_NEXTNODE) {
    // O MEU NÓ PRECISA RECEBER TODO O NÓ DE MERGE NO SEU YESNEXT E ATUALIZAR O SEU nextJoinNode(nó de merge)
    const NEW_NEXT = await findNodeById(tree, toUpdateId);

    if (NEW_NEXT) {
      // atualizar o lastJoinNode do nó encontrado(para atualização) com o meu node.id
      NEW_NEXT.lastJoinNode = node.id;

      // O NÓ ENCONTRADO VAI VIRAR OS ELEMENTOS DO NÓ ATUAL
      node.nextNode = NEW_NEXT;
      node.nextJoinNode = toUpdateId;
    } else node.nextNode = null;
  }

  // COLOCAR O FILHO DO NÓ ENCONTRADO, APONTANDO PARA SER O NOVO NÓ
  if (IS_TO_ENTER_YESNEXT) {
    // O MEU NÓ PRECISA RECEBER TODO O NÓ DE MERGE NO SEU YESNEXT E ATUALIZAR O SEU nextJoinNode(nó de merge)
    const NEW_YES_NEXT = await findNodeById(tree, toUpdateId);

    if (NEW_YES_NEXT) {
      // atualizar o lastJoinNode do nó encontrado(para atualização) com o meu node.id
      NEW_YES_NEXT.lastJoinNode = node.id;

      // O NÓ ENCONTRADO VAI VIRAR OS ELEMENTOS DO NÓ ATUAL
      node.YesNext = NEW_YES_NEXT;
      node.nextJoinNode = toUpdateId;
    } else node.YesNext = null;
  }

  if (node.nextNode && node.nextNode.id != node.lastNode) {
    await testRemoveAndJoinNodeService(
      node.nextNode,
      toDeleteId,
      toUpdateId,
      tree
    );
  }

  if (node.YesNext && node.YesNext.id != node.lastNode) {
    await testRemoveAndJoinNodeService(
      node.YesNext,
      toDeleteId,
      toUpdateId,
      tree
    );
  }

  return node;
}

export async function testRemoveAndJoinNode2Service(
  node,
  searchId,
  toUpdateId
) {
  const IS_TO_ENTER_NEXTNODE =
    node.nextNode &&
    node.nextNode.id === searchId &&
    node.nextNode.formatType !== "diamond";

  const IS_TO_ENTER_NEXTNODE_DIAMOND =
    node.nextNode &&
    node.nextNode.id === searchId &&
    node.nextNode.formatType === "diamond";

  const IS_TO_ENTER_YESNEXT =
    node.YesNext &&
    node.YesNext.id === searchId &&
    node.YesNext.formatType !== "diamond";

  const IS_TO_ENTER_YESNEXT_DIAMOND =
    node.YesNext &&
    node.YesNext.id === searchId &&
    node.YesNext.formatType === "diamond";

  if (IS_TO_ENTER_NEXTNODE) {
    node.nextNode.lastJoinNode = toUpdateId;

    return node;
  } else if (IS_TO_ENTER_NEXTNODE_DIAMOND) {
    node.nextNode.lastJoinNode = toUpdateId;

    return node;
  }

  // COLOCAR O FILHO DO NÓ ENCONTRADO, APONTANDO PARA SER O NOVO NÓ
  if (IS_TO_ENTER_YESNEXT) {
    node.YesNext.lastJoinNode = toUpdateId;

    return node;
  } else if (IS_TO_ENTER_YESNEXT_DIAMOND) {
    node.YesNext.lastJoinNode = toUpdateId;

    return node;
  }

  if (node.nextNode && node.nextNode.id != node.lastNode) {
    const updateNextNode = await testRemoveAndJoinNode2Service(
      node.nextNode,
      searchId,
      toUpdateId
    );

    if (updateNextNode === null) {
      node.nextNode = node.nextNode.nextNode;
    } else {
      node.nextNode = updateNextNode;
    }
  }

  if (node.YesNext && node.YesNext.id != node.lastNode) {
    const updateNextNode = await testRemoveAndJoinNode2Service(
      node.YesNext,
      searchId,
      toUpdateId
    );

    if (updateNextNode === null) {
      node.YesNext = node.YesNext.YesNext;
    } else {
      node.YesNext = updateNextNode;
    }
  }

  return node;
}

export async function findLastFinalToRemoveService(node, source) {
  if (node?.type.includes("final") || node[source] == null) return node;

  if (node[source])
    return await findLastFinalToRemoveService(node[source], source);

  return null;
}

// necessário receber um objeto com as seguintes chaves obrigatórias, para a montagem da árvore:
/**
 * 
 * 
 * 
 * 
       const obj = {
        steps_array: response.steps,
        current_audience_id: this.currentJourney.journey.audienceId,
        audience_list: this.audiencesList,
        template_sms_email_push_arr: this.templatesSMSList,
      };
 * 
 * 
 * 
 */

export async function buildTreeService({
  status,
  template_sms_email_push_arr,
  audience_list,
  current_audience_id,
  steps_array,
}) {
  const yesLastStepIdList = [];
  const nodeListArr = [];
  const nodeMap = {};
  let tree = {};

  let info = {
    template_sms_email_push_arr,
    audience_list,
    current_audience_id,
    steps_array,
  };

  let validated_infos = false;

  if (status !== "draft") {
    validated_infos = await validateInformationToBuildTree(info);
  }

  if (validated_infos.valid || status === "draft") {
    const BLOCK_TYPES = {
      selectAudience: {
        label: "Selecionar Audiência",
        //    stepLabel: "",
        isDataSelected: false,
        formatType: "circle",
        hasNextNode: true,
        color: "#9747ff",
        wasRenamed: false,
        icon: "mdi-calendar",
        lastNode: null,
      },
      sendPush: {
        label: "Disparo via Push",
        isDataSelected: false,
        formatType: "square",
        hasNextNode: true,
        color: "#9747ff",
        wasRenamed: false,
        icon: "mdi-message-processing",
        lastNode: null,
        fromClick: "next",
      },
      final: {
        label: "Final",
        isDataSelected: false,
        formatType: "circle",
        stepLabel: "",
        hasNextNode: false,
        color: "#2bae5c",
        icon: "$exit_to_app_rounded",
        nextNode: null,
        lastNode: null,
        wasRenamed: false,
        fromClick: "next",
      },
      sendEmail: {
        label: "Selecionar\ne-mail",
        formatType: "square",
        hasNextNode: true,
        isDataSelected: false,
        icon: "mdi-email",
        color: "#0b99ff",
        lastNode: null,
        wasRenamed: false,
        fromClick: "next",
      },
      sendSMS: {
        label: "Enviar SMS",
        formatType: "square",
        //     stepLabel: "",
        hasNextNode: true,
        icon: "mdi-message-processing",
        color: "#0b99ff",
        isDataSelected: false,
        lastNode: null,
        wasRenamed: false,
        fromClick: "next",
      },
      sendFacebook: {
        label: "Facebook",
        formatType: "square",
        hasNextNode: true,
        icon: "mdi-facebook",
        color: "#0b99ff",
        isDataSelected: false,
        lastNode: null,
        wasRenamed: false,
        fromClick: "next",
      },

      sendGoogle: {
        label: "Google",
        formatType: "circle",
        hasNextNode: true,
        icon: "mdi-google",
        color: "#ff3030",
        isDataSelected: false,
        lastNode: null,
        wasRenamed: false,
        fromClick: "next",
      },
      sendWhatsApp: {
        label: "WhatsApp",
        formatType: "circle",
        hasNextNode: true,
        icon: "mdi-whatsapp",
        color: "#14AE5C",
        isDataSelected: false,
        lastNode: null,
        wasRenamed: false,
        fromClick: "next",
      },
      await: {
        label: "Selecionar período",
        type: "await",
        icon: " $timer_rounded",
        //   stepLabel: "",
        formatType: "circle",
        hasNextNode: true,
        isDataSelected: false,
        color: "#9747ff",
        lastNode: null,
        wasRenamed: false,
        fromClick: "next",
      },
      selectAudienceFilter: {
        label: "Selecionar audiência filtrada",
        // stepLabel: "",
        type: "selectAudienceFilter",
        formatType: "diamond",
        hasNextNode: true,
        isDataSelected: false,
        color: "#F24822",
        lastNode: null,
        wasRenamed: false,
        NoNext: null,
        fromClick: "next",
      },
      selectTestABFilter: {
        label: "Teste A/B",
        type: "selectTestABFilter",
        // stepLabel: "",
        formatType: "diamond",
        hasNextNode: true,
        color: "#0b99ff",
        wasRenamed: false,
        isDataSelected: false,
        lastNode: null,
        NoNext: null,
        fromClick: "next",
      },
      mensageriaCheck: {
        label: "Divisão por interação",
        type: "mensageriaCheck",
        formatType: "diamond",
        selectedContentId: "",
        hasNextNode: true,
        icon: "mdi-email",
        color: "#9747ff",
        isDataSelected: false,
        lastNode: null,
        NoNext: null,
        nextNode: null,
        fromClick: "next",
      },
      tagUser: {
        label: "Tag",
        type: "tagUser",
        formatType: "circle",
        selectedContentId: "",
        hasNextNode: true,
        icon: "mdi-tag",
        color: "#0b99ff",
        isDataSelected: false,
        lastNode: null,
        NoNext: null,
        nextNode: null,
        fromClick: "next",
      },
    };

    steps_array.forEach((obj) => (nodeMap[obj.id] = obj));

    steps_array.forEach((obj) => {
      const PARENT_ID = obj.lastStepId;
      const DATA = obj;
      let NODE = null;

      // NECESSÁRIO PERCORRER AS CHAVES DO OBJETO PARA PODER PEGAR O ELEMENTO ADEQUADO, ASSIM, FAZENDO O NOVO MERGE DE INFORMAÇÕES
      for (const key in BLOCK_TYPES) {
        if (obj.type === "selectAudience") {
          NODE = Object.assign(DATA, BLOCK_TYPES[key]);
          // FAZER APONTAMENTO DO LASTNODE, POREM O LASTSTEPID ESTÁ ERRADO?
          NODE.lastNode = NODE.lastStepId;

          break;
        }

        if (obj.type.includes(key)) {
          NODE = _.assign(DATA, BLOCK_TYPES[key]);

          // NÃO PODE CRIAR NÓ DE MERGE QUANDO O CAMPO TEM ALGUM DOS CAMPOS ABAIXO, MESMO QUE ELE TENHA O yesLastStepId
          const FORBIDDEN_TYPES = [
            "selectAudienceFilter",
            "selectTestABFilter",
            "mensageriaCheck",
          ];

          // CASO MEU ELEMENTO TENHA A KEY UNION, DEVO CONTINUAR UTILIZANDO ELA NO MEU NÓ
          if (
            (key.includes("union") || NODE?.yesLastStepId != undefined) &&
            !FORBIDDEN_TYPES.includes(NODE.type)
          ) {
            if (!NODE.type.includes("union")) NODE.type = `union-${NODE.type}`;

            // FAZER APONTAMENTO DO LASTNODE, POREM O LASTSTEPID ESTÁ ERRADO?
            NODE.lastNode = NODE.lastStepId;

            // COM MEU ELEMENTO DE UNION ENCONTRADO, PRECISO ACHAR O yesLastStepId DELE E INSERIR O NÓ DE UNION NELE?
            if (NODE.yesLastStepId) {
              yesLastStepIdList.push({
                parentId: NODE.id,
                childId: NODE.yesLastStepId,
              });
            }
          }

          break;
        }
      }

      // MERGE DE OBJETOS, PARA O OBJETO TER TODAS A PROPRIEDADES
      NODE = Object.assign(DATA, BLOCK_TYPES[obj.type]);

      // VERIFICAR CADA TIPO DE INFORMAÇÃO PARA VERIFICAR SE JÁ TEM DADOS SELECIONADOS OU NÃO
      if (NODE.type === "selectAudience")
        if (current_audience_id) {
          NODE.isDataSelected = true;

          if (NODE?.stepLabel) {
            NODE.label = NODE?.stepLabel;
          } else {
            const AUDIENCE = audience_list.find(
              (d) => d.id === current_audience_id
            );
            if (AUDIENCE) NODE.label = AUDIENCE.name;
          }
        }

      if (
        NODE.type.includes("sendSMS") ||
        NODE.type.includes("sendEmail") ||
        NODE.type.includes("sendPush") ||
        NODE.type.includes("sendWhatsApp")
      )
        if (NODE?.stepLabel) {
          NODE.label = NODE?.stepLabel;
          NODE.isDataSelected = true;
        } else if (NODE.templateId) {
          NODE.isDataSelected = true;
          const TEMPLATE = template_sms_email_push_arr.find(
            (d) => d.id == NODE.templateId
          );
          if (TEMPLATE) NODE.label = TEMPLATE.templateName;
        }

      if (
        NODE.type.includes("sendFacebook") ||
        NODE.type.includes("sendGoogle")
      ) {
        NODE.label = NODE?.stepLabel ? NODE?.stepLabel : NODE.label;
        NODE.isDataSelected = true;

        NODE.fieldMap ??= NODE.fieldMap = "";
        NODE.collectionId ??= NODE.collectionId = "";
        NODE.collectionName ??= NODE.collectionName = "";
      }

      if (NODE.type.includes("await"))
        if (NODE?.stepLabel) {
          NODE.label = NODE?.stepLabel;
          NODE.isDataSelected = true;
        } else if (NODE.wait && NODE.waitValue) {
          NODE.isDataSelected = true;
          NODE.label = `${NODE.waitValue} ${NODE.wait}`;
        }

      if (NODE.type.includes("selectAudienceFilter"))
        if (NODE?.stepLabel) {
          NODE.label = NODE?.stepLabel;
          NODE.isDataSelected = true;
        }

      if (NODE.type.includes("selectTestABFilter"))
        if (NODE?.stepLabel) {
          NODE.label = NODE?.stepLabel;
          NODE.isDataSelected = true;
        } else if (NODE.wait && NODE.waitValue) {
          NODE.isDataSelected = true;
          NODE.label = `${NODE.waitValue} ${NODE.wait}`;
        }

      if (NODE.type.includes("mensageriaCheck"))
        if (NODE?.stepLabel) {
          NODE.label = NODE?.stepLabel;
          NODE.isDataSelected = true;
        }

      if (NODE.type.includes("tagUser"))
        if (NODE?.tags) {
          NODE.label = NODE?.tags;
          NODE.isDataSelected = true;
        }

      // SE NÃO TIVER O PARENTE ID, ELE É O PRIMEIRO NÓ (RAIZ)
      if (!PARENT_ID) {
        tree = NODE;
        nodeListArr.push(NODE);
      } else {
        const PARENT_NODE = nodeMap[PARENT_ID];

        // SE O YESNEXTID FOR NULO, O PROXIMO NÓ OBRIGATORIAMENTE É DO NEXTNODE
        if (PARENT_NODE && !PARENT_NODE.yesNextStepId) {
          PARENT_NODE.nextNode = {};
          PARENT_NODE.nextNode = NODE;
        }

        if (PARENT_NODE && PARENT_NODE?.yesNextStepId) {
          PARENT_NODE.YesNext = {};
          PARENT_NODE.YesNext = nodeMap[PARENT_NODE.yesNextStepId];

          if (PARENT_NODE.YesNext) PARENT_NODE.YesNext.fromClick = "yes";

          if (PARENT_NODE?.nextStepId) {
            PARENT_NODE.nextNode = {};
            PARENT_NODE.nextNode = nodeMap[PARENT_NODE.nextStepId];
          }
        }
        nodeListArr.push(NODE);
      }
    });

    for (const { parentId } of yesLastStepIdList) {
      const NODE_THAT_MAKES_MERGE = await findNodeById(tree, parentId);

      if (NODE_THAT_MAKES_MERGE) {
        // encontrar nó que faz merge (esse nó filho, vai receber o pai que requisitou seu merge, esse, será adicionado no YesNext do filho)
        const NODE_CHILD_TO_RECIEVE_FATHER = await findNodeById(
          tree,
          NODE_THAT_MAKES_MERGE.yesLastStepId
        );

        // PRECISO DE UM MÉTODO PARA INSERIR UM NÓ FINAL NO CAMPO DA ÁRVORE CASO ELE NÃO TENHA
        // ESSE CAMPO VAI SER REMOVIDO EM SEGUIDA PELO MÉTODO testRemoveAndJoinNode E
        let new_builded_tree =
          await findMergedNodeAndReplaceWithFinalNodeService(
            tree,
            NODE_CHILD_TO_RECIEVE_FATHER.id
          );

        // necessário encontrar o diamante parente do nó, para passar no método de merge
        let parent_diamond = await findFirstDiamondParentService(
          new_builded_tree,
          NODE_THAT_MAKES_MERGE
        );

        // objeto precisa ter obrigatoriamente essa estrutura e esses nomes de chaves
        const obj = {
          node: NODE_THAT_MAKES_MERGE,
          tree: new_builded_tree,
          parent_diamond: parent_diamond,
        };

        tree = await joinInformationsService(obj);
      }
    }

    return { tree, nodeListArr };
  } else {
    if (status !== "draft") throw new Error(validated_infos.message);
    return {
      tree: {},
      nodeListArr: [],
    };
  }
}

export async function findMergedNodeAndReplaceWithFinalNodeService(
  tree,
  nodeId
) {
  if (tree.id === nodeId) {
    if (tree.yesNextStepId && tree.YesNext == null) {
      tree.YesNext = generateFinalNodes();
      tree.YesNext.lastStepId = tree.id;
      tree.YesNext.lastNode = tree.id;
    }
  } else {
    if (tree.nextNode)
      await findMergedNodeAndReplaceWithFinalNodeService(tree.nextNode, nodeId);

    if (tree.YesNext)
      await findMergedNodeAndReplaceWithFinalNodeService(tree.YesNext, nodeId);
  }

  return tree;
}

export async function findFirstDiamondParentService(tree, nodeInfo) {
  if (nodeInfo.formatType == "diamond") return nodeInfo;

  if (nodeInfo.lastNode || nodeInfo?.lastStepId) {
    //  entontrar o nó que tem o lastId correspondente
    const LAST_NODE = await findNodeById(
      tree,
      nodeInfo.lastNode || nodeInfo.lastStepId
    );

    // se encontrar eu passo esse nó no método recursivo
    if (LAST_NODE) return await findFirstDiamondParentService(tree, LAST_NODE);
  }

  return null;
}

function generateFinalNodes() {
  return {
    id: generateUUID(),
    label: "Final",
    stepLabel: "",
    type: "final",
    formatType: "circle",
    hasNextNode: false,
    color: "#2bae5c",
    icon: "$exit_to_app_rounded",
    isDataSelected: false,
    nextNode: null,
    lastNode: null,
    lastJoinNode: null,
    nextJoinNode: null,
    fromClick: "next",
  };
}

function validateInformationToBuildTree(informations) {
  const is_valid = {
    valid: true,
  };
  const validations_messages = [];

  // if (!isKeyValidated(informations.template_sms_email_push_arr)) {
  //   validations_messages.push("Necessário informar array de templates");
  // }

  if (!isKeyValidated(informations.audience_list)) {
    validations_messages.push("Necessário informar array de audiências");
  }

  if (!isKeyValidated(informations.steps_array)) {
    validations_messages.push("Necessário informar array de steps");
  }

  if (!isKeyValidated(informations.current_audience_id)) {
    validations_messages.push("Necessário informar o id da audiência atual");
  }

  if (validations_messages.length) {
    is_valid.valid = false;
    is_valid.message = validations_messages.join(", ");
  }

  return is_valid;
}

function isKeyValidated(key) {
  return key != undefined || key != null;
}

// export async function testRemoveAndJoinNodeService(
//   node,
//   toDeleteId,
//   toUpdateId,
//   tree = null
// ) {
//   // preciso receber: a arvore, o nó que vai ser removido e o nó onde vai receber uma nova informação
//   const IS_TO_ENTER_NEXTNODE =
//     node.nextNode &&
//     node.nextNode.id === toDeleteId &&
//     node.nextNode.formatType !== "diamond";

//   const IS_TO_ENTER_YESNEXT =
//     node.YesNext &&
//     node.YesNext.id === toDeleteId &&
//     node.YesNext.formatType !== "diamond";

//   // const IS_TO_ENTER_NEXTNODE_DIAMOND =
//   //   node.nextNode &&
//   //   node.nextNode.id === toDeleteId &&
//   //   node.nextNode.formatType === "diamond";

//   // const IS_TO_ENTER_YESNEXT_DIAMOND =
//   //   node.YesNext &&
//   //   node.YesNext.id === toDeleteId &&
//   //   node.YesNext.formatType === "diamond";

//   if (IS_TO_ENTER_NEXTNODE) {
//     const NEW_NEXT = await findNodeById(tree, toUpdateId);

//     if (NEW_NEXT) {
//       NEW_NEXT.lastJoinNode = node.id;
//       node.nextNode = NEW_NEXT;
//       node.nextJoinNode = toUpdateId;
//     } else node.nextNode = null;

//     // if (NEW_YES_NEXT) node.nextNode = NEW_YES_NEXT;
//     // else node.nextNode = null;
//     // é necessário passar a ultima informação, no yesNext do cara
//     //  node.nextJoinNode = toUpdateId;
//     //  tenho que encontra o meu toUpdateId e atualizar ele com o lastJoinNode que vai receber o meu node.id
//     // await testRemoveAndJoinNode2Service(tree, toUpdateId, node.id);
//   }

//   // COLOCAR O FILHO DO NÓ ENCONTRADO, APONTANDO PARA SER O NOVO NÓ
//   if (IS_TO_ENTER_YESNEXT) {

//     // O MEU NÓ PRECISA RECEBER TODO O NÓ DE MERGE NO SEU YESNEXT E ATUALIZAR O SEU nextJoinNode
//     const NEW_YES_NEXT = await findNodeById(tree, toUpdateId);

//     if (NEW_YES_NEXT) {
//       //  tenho que encontra o meu toUpdateId e atualizar ele com o lastJoinNode que vai receber o meu node.id
//       // inserir dinamicamente a chave do nó, no nó de update
//       NEW_YES_NEXT.lastJoinNode = node.id;

//       // O NÓ ENCONTRADO VAI VIRAR OS ELEMENTOS DO NÓ
//       node.YesNext = NEW_YES_NEXT;
//       node.nextJoinNode = toUpdateId;
//     } else node.YesNext = null;

//     //  tenho que encontra o meu toUpdateId e atualizar ele com o lastJoinNode que vai receber o meu node.id
//     // let d = await testRemoveAndJoinNode2Service(tree, toUpdateId, node.id);
//   }

//   if (node.nextNode && node.nextNode.id != node.lastNode) {
//     await testRemoveAndJoinNodeService(
//       node.nextNode,
//       toDeleteId,
//       toUpdateId,
//       tree
//     );
//   }

//   if (node.YesNext && node.YesNext.id != node.lastNode) {
//     await testRemoveAndJoinNodeService(
//       node.YesNext,
//       toDeleteId,
//       toUpdateId,
//       tree
//     );
//   }

//   return node;
// }
